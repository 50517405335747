import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, Typography } from '@mui/material';

const BarGraph = () => {
  // chart color
  const theme = useTheme();
  const darkblueveryDark = theme.palette.darkblue.veryDark;
  const darkbluedark = theme.palette.darkblue.dark;
  const darkbluemain = theme.palette.darkblue.main;
  const darkbluelight = theme.palette.darkblue.light;
  const darkbluelighter = theme.palette.darkblue.lighter;

  const optionscolumnchart = {
    chart: {
      type: 'bar',
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      height: 295,
    },
    colors: [darkblueveryDark, darkbluedark, darkbluemain, darkbluelight, darkbluelighter],
    // colors: ['#c22f42', '#e3634b', '#e5b94c', '#90be5c', '#47aed1'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '45%',
        distributed: true,
        endingShape: 'rounded',
      },
    },

    xaxis: {
      categories: [
        ['Fatality'],
        ['Lost Time'],
        ['Restricted And Job Transfer'],
        ['Medical Treatment'],
        ['Other'],
      ],
      axisBorder: {
        show: true,
      },
    },
    yaxis: {
      title: {
        text: 'Total Percent Chemical',
      },
      labels: {
        show: true,
      },
    },
    tooltip: {
      theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
    },
  };
  const seriescolumnchart = [
    {
      name: '',
      data: [20, 15, 30, 25, 10],
    },
  ];

  return (
    <Card>
    
      <Typography
          variant="h5"
          fontWeight={600}
          textAlign="center"
          mb="20px"
          py={1}
          sx={{
            backgroundColor: theme.palette.slate.main,
            borderRadius: '10px',
          }}
        >
        Bar Graph Example
        </Typography>
        
      <CardContent>
        <Chart options={optionscolumnchart} series={seriescolumnchart} type="bar" height="295px" />
      </CardContent>
    </Card>
  );
};

export default BarGraph;
