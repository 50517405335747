import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
  UserID: null,
  Type: null,
  CompanyID: null,
  UserName: null,
  UserEmail: null,
  timestamp:null

};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.UserID = action.payload?.UserID;
      state.Type = action.payload?.Type;
      state.CompanyID = action.payload?.CompanyID;
      state.UserName = action.payload?.UserName;
      state.UserEmail = action.payload?.UserEmail;
      state.timestamp = action?.payload ? dayjs() : null

    },
  },
});

export const { setUser } = UserSlice.actions;

export default UserSlice.reducer;
