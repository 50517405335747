/* eslint-disable import/no-anonymous-default-export */
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';
import BarGraph from 'src/view/testing/bar-graph';

const Sorting = Loadable(lazy(() => import('../../view/testing/Sorting')));
const UserRoles = Loadable(lazy(() => import('../../view/testing/UserRole')));
const Doughnut = Loadable(lazy(() => import('../../view/testing/doughnut')));
const Typing = Loadable(lazy(() => import('../../view/testing/typing')));
const ImageUploader = Loadable(lazy(() => import('../../view/testing/ImageUploader')));
const Table = Loadable(lazy(() => import('../../view/testing/tables')));
const Editor = Loadable(lazy(() => import('../../view/testing/editor')));
const Form = Loadable(lazy(() => import('../../view/testing/form')));
const Filter = Loadable(lazy(() => import('../../view/testing/filtering')));
const Mixed = Loadable(lazy(() => import('../../view/testing/mixed')));
const ICS = Loadable(lazy(() => import('../../view/testing/ics/DateTime')));
const FilterWithDataTable = Loadable(lazy(() => import('../../view/testing/FilterWithDataTable')));
const UploadedImage = Loadable(
  lazy(() => import('../../view/testing/ImageUploader/UploadedImage')),
);
const Calendar = Loadable(lazy(() => import('../../view/testing/calender')));
const InputData = Loadable(lazy(() => import('../../view/testing/InputData')));
const RightModal = Loadable(lazy(() => import('../../view/testing/RightsModal/RightsModal')));

export default {
  path: 'testing',
  children: [
    { path: 'sorting', element: <Sorting /> },
    { path: 'users', element: <UserRoles /> },
    { path: 'doughnut', element: <Doughnut /> },
    { path: 'bar-graph', element: <BarGraph /> },
    { path: 'typing', element: <Typing /> },
    { path: 'image-uploader', element: <ImageUploader /> },
    { path: 'uploaded-image', element: <UploadedImage /> },
    { path: 'table', element: <Table /> },
    { path: 'editor', element: <Editor /> },
    { path: 'form', element: <Form /> },
    { path: 'filter', element: <Filter /> },
    { path: 'mixed', element: <Mixed /> },
    { path: 'ics', element: <ICS /> },
    { path: 'filter-data-table', element: <FilterWithDataTable /> },
    { path: 'calender', element: <Calendar /> },
    { path: 'input-data', element: <InputData /> },
    { path: 'right-modal', element: <RightModal /> },
  ],
};
