import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';

//AERS - masters routes
const ProcessElements = Loadable(lazy(() => import('src/view/aers/processElements')));
const ProcessElementsByID = Loadable(
  lazy(() => import('src/view/aers/processElements/ProcesElementByID')),
);

const Suppliers = Loadable(lazy(() => import('src/view/aers/suppliers')));
const EnergyUtility = Loadable(lazy(() => import('src/view/aers/energyUtility')));
const Units = Loadable(lazy(() => import('src/view/aers/units')));
const Products = Loadable(lazy(() => import('src/view/aers/products')));
const AddProduct = Loadable(lazy(() => import('src/view/aers/products/AddProduct')));
const EditProduct = Loadable(lazy(() => import('src/view/aers/products/EditProduct')));
const ProductListByID = Loadable(lazy(() => import('src/view/aers/products/ProductListByID')));

const AssignProcess = Loadable(lazy(() => import('src/view/aers/assignProcess')));
const AddAssignProcess = Loadable(
  lazy(() => import('src/view/aers/assignProcess/AddAssignProcess')),
);
const AddAssignProduct = Loadable(
  lazy(() => import('src/view/aers/assignProcess/AddAssignProduct')),
);

// AERS - reports
const ProcessReport = Loadable(
  lazy(() => import('src/view/aers/report/materialReport/MaterialReport')),
);
const AddProcessReport = Loadable(
  lazy(() => import('src/view/aers/report/materialReport/AddMaterialReport')),
);
const EditProcessReport = Loadable(
  lazy(() => import('src/view/aers/report/materialReport/EditMaterialReport')),
);

const WaterUsageReport = Loadable(
  lazy(() => import('src/view/aers/report/waterUsage/WaterUsageReport')),
);
const AddWaterUsageReport = Loadable(
  lazy(() => import('src/view/aers/report/waterUsage/AddWaterUsageReport')),
);
const EditWaterUsageReport = Loadable(
  lazy(() => import('src/view/aers/report/waterUsage/EditWaterUsagesReport')),
);

const WasteGenerationReport = Loadable(
  lazy(() => import('src/view/aers/report/wasteGeneration/WasteGenerationReport')),
);
const AddWasteGenerationReport = Loadable(
  lazy(() => import('src/view/aers/report/wasteGeneration/AddWasteGenerationReport')),
);
const EditWasteGenerationReport = Loadable(
  lazy(() => import('src/view/aers/report/wasteGeneration/EditWasteGenerationReport')),
);

const EnergyConsuptionReport = Loadable(
  lazy(() => import('src/view/aers/report/energyConsumption/EnergyConsuptionReport')),
);
const AddEnergyConsuptionReport = Loadable(
  lazy(() => import('src/view/aers/report/energyConsumption/AddEnergyConsuptionReport')),
);
const EditEnergyConsuptionReport = Loadable(
  lazy(() => import('src/view/aers/report/energyConsumption/EditEnergyConsuptionReport')),
);

const EmployeeWellnessReport = Loadable(
  lazy(() => import('src/view/aers/report/employeeWellness/EmployeeWellnessReport')),
);
const AddEmployeeWellnessReport = Loadable(
  lazy(() => import('src/view/aers/report/employeeWellness/AddEmployeeWellnessReport')),
);
const EditEmplyoeeWellnessReport = Loadable(
  lazy(() => import('src/view/aers/report/employeeWellness/EditEmployeeWellnessReport')),
);

const CommunityEngagementReport = Loadable(
  lazy(() => import('src/view/aers/report/communityEngagement/CommunityEngagementReport')),
);
const AddCommunityEngagementReport = Loadable(
  lazy(() => import('src/view/aers/report/communityEngagement/AddCommunityEngagementReport')),
);
const EditCommunityEngagementReport = Loadable(
  lazy(() => import('src/view/aers/report/communityEngagement/EditCommunityEngagementReport')),
);

const BiodiversityReport = Loadable(
  lazy(() => import('src/view/aers/report/biodiversity/BiodiversityReport')),
);
const AddBiodiversityReport = Loadable(
  lazy(() => import('src/view/aers/report/biodiversity/AddBiodiversity')),
);
const EditBiodiversityReport = Loadable(
  lazy(() => import('src/view/aers/report/biodiversity/EditBiodiversity')),
);

const RiskMangementReport = Loadable(
  lazy(() => import('src/view/aers/report/riskManagement/RiskManagementReport')),
);
const AddRiskManagementReport = Loadable(
  lazy(() => import('src/view/aers/report/riskManagement/AddRiskManagementReport')),
);
const EditRiskManagementReport = Loadable(
  lazy(() => import('src/view/aers/report/riskManagement/EditRiskManagementReport')),
);

const ComplianceReport = Loadable(
  lazy(() => import('src/view/aers/report/compliance/ComplianceReport')),
);
const AddComplianceReport = Loadable(
  lazy(() => import('src/view/aers/report/compliance/AddComplianceReport')),
);
const EditComplianceReport = Loadable(
  lazy(() => import('src/view/aers/report/compliance/EditComplianceReport')),
);

const PollutantReport = Loadable(
  lazy(() => import('src/view/aers/report/pollutantReport/PollutantReport')),
);
const PollutantReportExcel = Loadable(
  lazy(() => import('src/view/aers/report/pollutantReport/PollutantReportExcel')),
);
const AddPollutantReport = Loadable(
  lazy(() => import('src/view/aers/report/pollutantReport/AddPollutantReport')),
);
const EditPollutantReport = Loadable(
  lazy(() => import('src/view/aers/report/pollutantReport/EditPollutantReport')),
);
const AssignProcessElement = Loadable(
  lazy(() => import('src/view/aers/assignProcess/AssignProcessElement')),
);

const AddGHGEmission = Loadable(
  lazy(() => import('src/view/aers/report/ghgemission/AddGHGEmission')),
);
const EditGHGEmission = Loadable(
  lazy(() => import('src/view/aers/report/ghgemission/EditGHGEmission')),
);
const GHGEmissionReport = Loadable(
  lazy(() => import('src/view/aers/report/ghgemission/GHGEmissionReport')),
);
const GHGEmissionReportExcel = Loadable(
  lazy(() => import('src/view/aers/report/ghgemission/GHGEmissionReportExcel')),
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'aers',
  children: [
    {
      path: '',
      children: [
        {
          path: 'process-elements',
          children: [
            {
              path: '',
              element: <ProcessElements />,
            },
          ],
        },
        {
          path: 'environment-process-element/:ParentID',
          children: [
            {
              path: '',
              element: <ProcessElementsByID />,
            },
            {
              path: 'product/:ProcessID',
              children: [
                {
                  path: '',
                  element: <ProductListByID />,
                },
                {
                  path: 'add',
                  element: <AddProduct />,
                },
                {
                  path: 'edit/:ProductID',
                  element: <EditProduct />,
                },

              ],
            },
          ],
        },
        {
          path: 'social-process-element/:ParentID',
          children: [
            {
              path: '',
              element: <ProcessElementsByID />,
            },
            {
              path: 'product/:ProcessID',
              children: [
                {
                  path: '',
                  element: <ProductListByID />,
                },
                {
                  path: 'add',
                  element: <AddProduct />,
                },
                {
                  path: 'edit/:ProductID',
                  element: <EditProduct />,
                },

              ],
            },
          ],
        },
        {
          path: 'governance-process-element/:ParentID',
          children: [
            {
              path: '',
              element: <ProcessElementsByID />,
            },
            {
              path: 'product/:ProcessID',
              children: [
                {
                  path: '',
                  element: <ProductListByID />,
                },
                {
                  path: 'add',
                  element: <AddProduct />,
                },
                {
                  path: 'edit/:ProductID',
                  element: <EditProduct />,
                },

              ],
            },
          ],
        },
        {
          path: 'product-element/:ParentID',
          element: <ProductListByID />,
        },
        {
          path: 'suppliers',
          children: [
            {
              path: '',
              element: <Suppliers />,
            },
          ],
        },
        {
          path: 'energy-and-utility',
          children: [
            {
              path: '',
              element: <EnergyUtility />,
            },
          ],
        },
        {
          path: 'units',
          children: [
            {
              path: '',
              element: <Units />,
            },
          ],
        },
        {
          path: 'products',
          children: [
            {
              path: '',
              children: [
                {
                  path: '',
                  element: <Products />,
                },
                {
                  path: 'add',
                  element: <AddProduct />,
                },
                {
                  path: 'edit/:ProductID',
                  element: <EditProduct />,
                },
              ],
            },
          ],
        },

        {
          path: 'assign-process',
          children: [
            {
              path: '',
              element: <AssignProcess />,
            },
            {
              path: ':ProcessParentID',
              element: <AssignProcessElement />,
            },
            {
              path: 'add',
              element: <AddAssignProcess />,
            },
          ],
        },
        {
          path: 'assign-product',
          children: [
            {
              path: 'add',
              element: <AddAssignProduct />,
            },
          ],
        },
      ],
    },
    {
      path: 'report',
      children: [
        {
          path: 'material/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <ProcessReport />,
            },
            {
              path: 'add',
              element: <AddProcessReport />,
            },
            {
              path: 'edit/:SectionID',
              element: <EditProcessReport />,
            },
          ],
        },
        {
          path: 'pollutant/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <PollutantReport />,
            },
            {
              path: 'add',
              element: <AddPollutantReport />,
            },
            {
              path: ':TemplateID',
              element: <PollutantReportExcel />,
            },
            {
              path: 'edit/:TemplateID',
              element: <EditPollutantReport />,
            },
          ],
        },
        {
          path: 'water-usage/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <WaterUsageReport />,
            },
            {
              path: 'add',
              element: <AddWaterUsageReport />,
            },
            {
              path: 'edit/:UsageReportID',
              element: <EditWaterUsageReport />,
            },
          ],
        },
        {
          path: 'waste-generation/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <WasteGenerationReport />,
            },
            {
              path: 'add',
              element: <AddWasteGenerationReport />,
            },
            {
              path: 'edit/:ReportID',
              element: <EditWasteGenerationReport />,
            },
          ],
        },
        {
          path: 'energy-consumption/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <EnergyConsuptionReport />,
            },
            {
              path: 'add',
              element: <AddEnergyConsuptionReport />,
            },
            {
              path: 'edit/:ReportID',
              element: <EditEnergyConsuptionReport />,
            },
          ],
        },

        {
          path: 'employee-wellness/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <EmployeeWellnessReport />,
            },
            {
              path: 'add',
              element: <AddEmployeeWellnessReport />,
            },
            {
              path: 'edit/:ReportID',
              element: <EditEmplyoeeWellnessReport />,
            },
          ],
        },
        {
          path: 'community-engagment/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <CommunityEngagementReport />,
            },
            {
              path: 'add',
              element: <AddCommunityEngagementReport />,
            },
            {
              path: 'edit/:ReportID',
              element: <EditCommunityEngagementReport />,
            },
          ],
        },
        {
          path: 'biodiversity/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <BiodiversityReport />,
            },
            {
              path: 'add',
              element: <AddBiodiversityReport />,
            },
            {
              path: 'edit/:ReportID',
              element: <EditBiodiversityReport />,
            },
          ],
        },
        {
          path: 'risk-management/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <RiskMangementReport />,
            },
            {
              path: 'add',
              element: <AddRiskManagementReport />,
            },
            {
              path: 'edit/:ReportID',
              element: <EditRiskManagementReport />,
            },
          ],
        },
        {
          path: 'compliance/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <ComplianceReport />,
            },
            {
              path: 'add',
              element: <AddComplianceReport />,
            },
            {
              path: 'edit/:ReportID',
              element: <EditComplianceReport />,
            },
          ],
        },
        {
          path: 'ghg-emission/:CompanyID/:LocationID',
          children: [
            {
              path: '',
              element: <GHGEmissionReport />,
            },
            {
              path: 'add',
              element: <AddGHGEmission />,
            },
            {
              path: ':TemplateID',
              element: <GHGEmissionReportExcel />,
            },
            {
              path: 'edit/:TemplateID',
              element: <EditGHGEmission />,
            },
          ],
        },
      ],
    },
  ],
};
