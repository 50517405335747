import { CssBaseline, ThemeProvider, useTheme } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { ThemeSettings } from './theme/Theme';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import './index.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MaterialDesignContent, SnackbarProvider, useSnackbar } from 'notistack';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import styled from '@emotion/styled';
import ErrorDialog from './components/common/ErrorDialog';
import { IconButton } from '@mui/material';
import { IconCircleX } from '@tabler/icons';


const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconCircleX />
    </IconButton>
  );
};

function App() {
  const routing = useRoutes(Router());
  const theme = ThemeSettings();
  const themeFromTheme = useTheme();
  const queryClient = new QueryClient();

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: '#afe6e3',
      color: themeFromTheme.palette.text.secondary,
      border: '1px solid',
      borderColor: themeFromTheme.palette.success.light,
      boxShadow: 'none',
      width: '100%',
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: '#FDEDE8',
      color: themeFromTheme.palette.text.secondary,
      border: '1px solid',
      borderColor: themeFromTheme.palette.error.light,
      boxShadow: 'none',
      width: '100%',
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <ScrollToTop>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider
              Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
              }}
              maxSnack={5}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              autoHideDuration={25000}
              action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            >
              {routing}
            </SnackbarProvider>
         
            <ErrorDialog />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ScrollToTop>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
