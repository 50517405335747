const baselightTheme = {
  direction: 'ltr',
  palette: {
 // New Colors
 darkblue: {
  verylighter: '#d3deeb',
  lighter: '#7a9cc4',
  light: '#4876b8',
  main: '#3a5c84',
  dark: '#2c4563',
  veryDark: '#6c768c',
  contrastText: '#ffffff',
},

    blue: {
      veryLighter:'#9dc3e6',
      lighter:'#8faadc',
      light: '#4270c1',
      main: '#2f5597',
      dark: '#203863',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#7dd3fc',
      main: '#082f49',
      dark: '#051c2c',
      contrastText: '#ffffff',
    },
    // secondary: {
    //   light: '#d4d5d7',
    //   main: '#272b39',
    //   dark: '#101117',
    //   contrastText: '#ffffff',
    // },
    primary: {
      main: '#e67d34',
      light: '#ec9c61',
      lighter:'#FDEDE8',
      dark: '#e0600d',
      contrastText: '#ffffff',
    },
    slate: {
      main: '#cbd5e1',
      light: '#f1f5f9',
      dark: '#64748b',
      contrastText: '#ffffff',
    },
    success: {
      main: '#13DEB9',
      light: '#E6FFFA',
      dark: '#02b3a9',
      contrastText: '#ffffff',
    },
    info: {
      main: '#539BFF',
      light: '#EBF3FE',
      dark: '#1682d4',
      contrastText: '#ffffff',
    },
    error: {
      main: '#FA896B',
      light: '#FDEDE8',
      dark: '#f3704d',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FFAE1F',
      light: '#FEF5E5',
      dark: '#ae8e59',
      contrastText: '#ffffff',
    },
    purple: {
      A50: '#EBF3FE',
      A100: '#6610f2',
      A200: '#557fb9',
    },
    shema: {
      lighter: '#f3f4e5',
      light: '#e8eaca',
      main: '#d1d595',
      dark: '#c5ca7b',
      contrastText: '#ffffff',
    },
    gray: {
      lighter: '#ebebeb',
      light: '#e0e0e0',
      main: '#cccccc',
      dark: '#525252',
      contrastText: '#ffffff',
    },

    // grey: {
    //   100: '#F2F6FA',
    //   200: '#EAEFF4',
    //   300: '#DFE5EF',
    //   400: '#7C8FAC',
    //   500: '#5A6A85',
    //   600: '#2A3547',
    // },
    text: {
      primary: '#2A3547',
      secondary: '#082f49',
      contrastText: '#ffffff',
      grayLabel:"#9d9d9d",
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: '#f6f9fc',
    },
    divider: '#e5eaef',
    background: {
      default: '#ffffff',
    },
  },
};

const baseDarkTheme = {
  direction: 'ltr',
  palette: {
    primary: {
      main: '#5D87FF',
      light: '#ECF2FF',
      dark: '#4570EA',
    },
    secondary: {
      main: '#e46c09',
      light: '#1C455D',
      dark: '#173f98',
    },
    success: {
      main: '#13DEB9',
      light: '#1B3C48',
      dark: '#02b3a9',
      contrastText: '#ffffff',
    },
    info: {
      main: '#539BFF',
      light: '#223662',
      dark: '#1682d4',
      contrastText: '#ffffff',
    },
    error: {
      main: '#FA896B',
      light: '#4B313D',
      dark: '#f3704d',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FFAE1F',
      light: '#4D3A2A',
      dark: '#ae8e59',
      contrastText: '#ffffff',
    },
    purple: {
      A50: '#EBF3FE',
      A100: '#6610f2',
      A200: '#557fb9',
    },
    grey: {
      100: '#333F55',
      200: '#465670',
      300: '#7C8FAC',
      400: '#DFE5EF',
      500: '#EAEFF4',
      600: '#F2F6FA',
      A700: '#465670',
    },
    text: {
      primary: '#EAEFF4',
      secondary: '#7C8FAC',
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: '#333F55',
    },
    divider: '#333F55',
    background: {
      default: '#171c23',
      dark: '#171c23',
      paper: '#171c23',
    },
  },
};

export { baseDarkTheme, baselightTheme };
