import { combineReducers, configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './slice/customizer/CustomizerSlice';
import UserReducer from './slice/user/UserSlice';
import AppReducer from './slice/app/AppSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

// configration of persist storage
const persistConfig = {
  key: 'root',
  storage: storage,
};

// combine all slice into single reducer
const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  user: UserReducer,
  app:AppReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export default store;
