import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

/* ****** Routes *****  */
import TestingRouter from './pages/TestingRouter';
import UserRouter from './pages/UserRouter';
import MasterRouter from './pages/MasterRouter';
import Loadable, { MyLoadable } from 'src/layout/full/loadable/Loadable';
import { useSelector } from 'react-redux';
import RightRouter from './pages/RightRouter';
import LogRouter from './pages/LogRouter';
import ChersRouter from './pages/ChersRouter';
import AersRouter from './pages/AersRouter';
import SheaRouter from './pages/SheaRouter';
import TRACIRouter from './pages/traciRouter';
import AMLARouter from './pages/amlaRouter';
import MOCARouter from './pages/mocaRouter';
import LmsRouter from './pages/LmsRouter';

/* ***Layouts**** */
const FullLayout = MyLoadable(lazy(() => import('../layout/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layout/blank/BlankLayout')));

/* ******Authentication and Others**** */
const ComingSoon = Loadable(lazy(() => import('../view/comingSoon/index')));
const Login = Loadable(lazy(() => import('../view/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('../view/authentication/ForgotPassword')));
const Error = Loadable(lazy(() => import('../view/authentication/Error')));
const AuthError = Loadable(lazy(() => import('../view/authentication/AuthError')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../view/dashboard')));

//company routes
const Company = Loadable(lazy(() => import('src/view/company')));
const AddCompany = Loadable(lazy(() => import('src/view/company/AddCompany')));
const EditCompany = Loadable(lazy(() => import('src/view/company/EditCompany')));
const ViewCompany = Loadable(lazy(() => import('src/view/company/ViewCompany')));

//pdf routes
const PDF = Loadable(lazy(() => import('src/view/selt/pdf/index')));
const AddPDF = Loadable(lazy(() => import('src/view/selt/pdf/AddPDF')));
const EditPDF = Loadable(lazy(() => import('src/view/selt/pdf/EditPDF')));
const ViewPDF = Loadable(lazy(() => import('src/view/selt/pdf/ViewPDF')));

const AllRoutes = () => {
  const { Type } = useSelector((state) => state.user);
  const Router = [
    {
      path: '/',
      element: <FullLayout />,
      children: [
        {
          index: true,
          element:
            Type === 2 ? (
              <Navigate to="/my-company" />
            ) : Type === 4 ? (
              <Navigate to="/chers/chemicals" />
            ) : (
              <Navigate to="/dashboard" />
            ),
        },
        { path: '/dashboard', exact: true, element: <Dashboard /> },
        {
          path: '/my-company',
          exact: true,
          element: <ViewCompany superUser={true} />,
        },
        {
          path: 'companies',
          children: [
            {
              path: '',
              element: <Company />,
            },
            {
              path: 'add',
              element: <AddCompany />,
            },
            {
              path: 'edit/:CompanyID',
              element: <EditCompany />,
            },
            {
              path: 'view-company/:CompanyID',
              element: <ViewCompany />,
            },
          ],
        },
        {
          path: 'selt',
          children: [
            {
              path: 'pdf-document',
              children: [
                {
                  path: '',
                  element: <PDF />,
                },
                {
                  path: 'add',
                  element: <AddPDF />,
                },
                {
                  path: 'view/:DocumentID',
                  element: <ViewPDF />,
                },
                {
                  path: 'edit/:DocumentID',
                  element: <EditPDF />,
                },
              ],
            },
          ],
        },
        MasterRouter,
        UserRouter,
        RightRouter,
        LogRouter,
        TestingRouter,
        ChersRouter,
        AersRouter,
        SheaRouter,
        TRACIRouter,
        AMLARouter,
        MOCARouter,
        LmsRouter,
        { path: '*', element: <ComingSoon /> },
      ],
    },

    {
      path: '/',
      element: <BlankLayout />,
      children: [
        { path: '/auth/404', element: <Error /> },
        { path: '/auth/login', element: <Login /> },
        { path: '/auth/auth-error', element: <AuthError /> },
        { path: '/auth/forgot-password', element: <ForgotPassword /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
  ];

  return Router;
};

export default AllRoutes;
