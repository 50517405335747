import React, { Suspense } from "react";
import Spinner from "src/view/spinner/Spinner";


const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Spinner />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;


export function MyLoadable(Component) {
  return function ReturnComponent(props) {
    return (
      <Suspense fallback={<Spinner />}>
        <Component {...props} />
      </Suspense>
    );
  };
}