import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';

const UserTypes = Loadable(lazy(() => import('src/view/rights/userTypes')));
const Modules = Loadable(lazy(() => import('src/view/rights/module')));
const UserRights = Loadable(lazy(() => import('src/view/rights/userRights')));

const Authorities = Loadable(lazy(() => import('src/view/rights/authorities')));
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'rights',
  children: [
    {
      path: 'user-rights',
      element: <UserRights />,
    },
    { path: 'modules', element: <Modules /> },
    { path: 'user-types', element: <UserTypes /> },
    { path: 'authorities', element: <Authorities /> },
  ],
};
