import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';

//chers - masters routes
const Chemicals = Loadable(lazy(() => import('src/view/chers/chemicals')));
const AddChemical = Loadable(lazy(() => import('src/view/chers/chemicals/AddChemical')));
const EditChemical = Loadable(lazy(() => import('src/view/chers/chemicals/EditChemical')));
const Assignment = Loadable(lazy(() => import('src/view/chers/chemicals/assignment')));
const HealthHazard = Loadable(lazy(() => import('src/view/chers/healthHazard')));
const PhysicalHazard = Loadable(lazy(() => import('src/view/chers/physicalHazard')));
const EnvironmentalHazard = Loadable(lazy(() => import('src/view/chers/environmentalHazard')));
const HazardStatement = Loadable(lazy(() => import('src/view/chers/hazardStatement')));
const PrecautionaryStatement = Loadable(
  lazy(() => import('src/view/chers/precautionaryStatement')),
);
const StorageStatement = Loadable(lazy(() => import('src/view/chers/storageStatement')));
const Responses = Loadable(lazy(() => import('src/view/chers/responses')));
const Properties = Loadable(lazy(() => import('src/view/chers/properties')));
const ContainerTypes = Loadable(lazy(() => import('src/view/chers/containerTypes')));
const Units = Loadable(lazy(() => import('src/view/chers/units')));
const SignalWords = Loadable(lazy(() => import('src/view/chers/signalWords')));
const HazardClasses = Loadable(lazy(() => import('src/view/chers/hazardClasses')));
const PackingGroups = Loadable(lazy(() => import('src/view/chers/packingGroups')));
const Temperatures = Loadable(lazy(() => import('src/view/chers/temperatures')));
const Pressures = Loadable(lazy(() => import('src/view/chers/pressures')));
const GHSLabel = Loadable(lazy(() => import('src/view/chers/ghsLabel')));
const Placards = Loadable(lazy(() => import('src/view/chers/placards')));
const Components = Loadable(lazy(() => import('src/view/chers/components')));
const StorageLocation = Loadable(lazy(() => import('src/view/chers/storageLocation')));
const ChangeRequest = Loadable(lazy(() => import('src/view/chers/changeRequests')));
const AssignmentLocation = Loadable(lazy(() => import('src/view/chers/chemicals/assignment/AssignmentLocations')));
const Storage = Loadable(lazy(() => import('src/view/chers/chemicals/assignment/Storage')));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'chers',
  children: [
    {
      path: '',
      children: [
        {
          path: 'health-hazard',
          children: [
            {
              path: '',
              element: <HealthHazard />,
            },
          ],
        },
        {
          path: 'chemicals',
          children: [
            {
              path: '',
              element: <Chemicals />,
            },
            {
              path: 'add',
              element: <AddChemical />,
            },
            {
              path: 'edit/:ChemicalID',
              element: <EditChemical />,
            },
            {
              path: 'assignment/:ChemicalID',
              element: <Assignment />,
             
            },
            {
              path: 'assignment/:ChemicalID/locations/:CompanyID',
              element: <AssignmentLocation />,
            },
            {
              path: 'assignment/:ChemicalID/locations/:CompanyID/storage/:LocationID',
              element: <Storage />,
            },
          ],
        },
        {
          path: 'physical-hazard',
          children: [
            {
              path: '',
              element: <PhysicalHazard />,
            },
          ],
        },
        {
          path: 'environmental-hazard',
          children: [
            {
              path: '',
              element: <EnvironmentalHazard />,
            },
          ],
        },
        {
          path: 'hazard-statement',
          children: [
            {
              path: '',
              element: <HazardStatement />,
            },
          ],
        },
        {
          path: 'precautionary-statement',
          children: [
            {
              path: '',
              element: <PrecautionaryStatement />,
            },
          ],
        },
        {
          path: 'storage-statement',
          children: [
            {
              path: '',
              element: <StorageStatement />,
            },
          ],
        },
        {
          path: 'responses',
          children: [
            {
              path: '',
              element: <Responses />,
            },
          ],
        },
        {
          path: 'properties',
          children: [
            {
              path: '',
              element: <Properties />,
            },
          ],
        },
        {
          path: 'container-types',
          children: [
            {
              path: '',
              element: <ContainerTypes />,
            },
          ],
        },
        {
          path: 'units',
          children: [
            {
              path: '',
              element: <Units />,
            },
          ],
        },
        {
          path: 'signal-words',
          children: [
            {
              path: '',
              element: <SignalWords />,
            },
          ],
        },
        {
          path: 'hazard-classes',
          children: [
            {
              path: '',
              element: <HazardClasses />,
            },
          ],
        },
        {
          path: 'packing-groups',
          children: [
            {
              path: '',
              element: <PackingGroups />,
            },
          ],
        },
        {
          path: 'temperatures',
          children: [
            {
              path: '',
              element: <Temperatures />,
            },
          ],
        },
        {
          path: '',
          children: [
            {
              path: 'pressure',
              element: <Pressures />,
            },
          ],
        },
        {
          path: '',
          children: [
            {
              path: 'ghs-label',
              element: <GHSLabel />,
            },
          ],
        },
        {
          path: '',
          children: [
            {
              path: 'placards',
              element: <Placards />,
            },
          ],
        },
        {
          path: '',
          children: [
            {
              path: 'components',
              element: <Components />,
            },
          ],
        },
        {
          path: '',
          children: [
            {
              path: 'storage-location',
              element: <StorageLocation />,
            },
          ],
        },
        {
          path: '',
          children: [
            {
              path: 'change-request',
              element: <ChangeRequest />,
            },
          ],
        },
      ],
    },
  ],
};
